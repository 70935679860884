import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import mapController from './MapController';
import ApartmentCard from './left_panel/ApartmentCard';

// Создаем кастомную иконку для маркера
const homeIcon = new L.Icon({
  iconUrl: '/images/home.svg',
  iconSize: [16, 16],
  iconAnchor: [8, 8],
  popupAnchor: [0, -32],
});

const specialIcon = new L.Icon({
  iconUrl: '/images/location_pin.svg',  // Подставь сюда иконку специального маркера
  iconSize: [38, 38],
  iconAnchor: [19, 38]
});

const MapComponent = () => {
  const [visiblelocations, setVisiblelocations] = useState([]);
  const [center, setCenter] = useState([48.157736, 17.135746]);
  const [zoom, setZoom] = useState(13);
  const lastBoundsRef = useRef(null);
  const componentId = 'MapComponent';

  const [specialMarkerPosition, setSpecialMarkerPosition] = useState(null);
  const [isSpecialMarkerVisible, setIsSpecialMarkerVisible] = useState(false);

  useEffect(() => {
    // Регистрируем коллбек для обновления видимых апартаментов
    mapController.addDataChangedListener(componentId, () => {
      setVisiblelocations(mapController.GetData());
    });

    // Убираем коллбек при размонтировании
    return () => {
      mapController.removeDataChangedListener(componentId);
    };
  }, []);

  // Функция для обновления видимых маркеров и отправки границ в mapController
  const updateVisibleMarkers = (map) => {
    if (!map) {
      console.warn('Карта ещё не загружена');
      return;
    }

    const bounds = map.getBounds();
    const topLeft = bounds.getNorthWest();
    const bottomRight = bounds.getSouthEast();

    // Проверяем, изменились ли границы с последнего раза
    if (!lastBoundsRef.current || !bounds.equals(lastBoundsRef.current)) {
      lastBoundsRef.current = bounds;
      mapController.setBounds(topLeft.lat, topLeft.lng, bottomRight.lat, bottomRight.lng);
    }
  };

  // Используем событие карты для обновления маркеров
  const MapEvents = () => {
    const map = useMapEvent('moveend', () => {
      updateVisibleMarkers(map);
    });

    useEffect(() => {
      if (map) {
        updateVisibleMarkers(map);
      }
    }, [map]);

    return null;
  };

  // Функция для отображения специального маркера в нужной позиции
  mapController.showSpecialMarker = (position) => {
    setSpecialMarkerPosition(position);
    setIsSpecialMarkerVisible(true);
  };

  // Функция для скрытия специального маркера
  mapController.hideSpecialMarker = () => {
    setIsSpecialMarkerVisible(false);
  };

  return (
    <MapContainer
      center={center}
      zoom={zoom}
      minZoom={9}
      className="leaflet-container"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      {/* Включаем компонент для обработки событий */}
      <MapEvents />

      {/* Отображение маркеров с кастомной иконкой */}
      {visiblelocations.map((location, locationIndx) => (
        <Marker
          key={locationIndx}
          position={[location.latitude, location.longitude]}
          icon={homeIcon}
        >
          <Popup>
            <div style={{ width: '100%', minWidth: '400px', maxHeight: '300px', overflowY: 'auto' }}>
              <h3>{location.name}</h3>
                {location.apartments.map((apartment) => (
                  <ApartmentCard key={apartment.url} apartment={{
                    ...apartment,
                    latitude: location.latitude,
                    longitude: location.longitude
                  }} className="limited-height-card" />
                ))}
            </div>
          </Popup>
        </Marker>
      ))}

      {/* Специальный маркер, который может быть показан или скрыт */}
      {isSpecialMarkerVisible && specialMarkerPosition && (
        <Marker position={specialMarkerPosition} icon={specialIcon}>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapComponent;
