import React, { useState, useEffect } from 'react';
import ApartmentCard from './ApartmentCard';
import mapController from '../MapController';

function ApartmentList() {
  const [data, setData] = useState(mapController.GetData());

  const componentId = 'ApartmentList';

  useEffect(() => {
    // Регистрируем коллбек на изменение данных с уникальным id
    mapController.addDataChangedListener(componentId, () => {
      setData(mapController.GetData());
    });

    // Убираем коллбек при размонтировании компонента
    return () => {
      mapController.removeDataChangedListener(componentId);
    };
  }, []);

  return (
    <div>
      {data.map((location) => (
        location.apartments.map((apartment) => (
          <ApartmentCard key={apartment.url} apartment={{
            ...apartment,
            latitude: location.latitude,
            longitude: location.longitude
          }} />
        ))
      ))}
    </div>
  );
}

export default ApartmentList;
