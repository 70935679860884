
class MapController {
    constructor() {
        this.data = [];
        this.price_range = { min: 700, max: 1000 };
        this.area_range = { min: 50, max: "" };
        this.bounds = null
        this.onDataChangedSubscriptions = {};
    }

    setBounds(topLeftLat, topLeftLng, bottomRightLat, bottomRightLng)
    {
        this.bounds = {
            topLeftLat: topLeftLat,
            topLeftLng: topLeftLng,
            bottomRightLat: bottomRightLat,
            bottomRightLng: bottomRightLng
        };
        this.fetchApartments()
    }

    addDataChangedListener(id, callback) {
        this.onDataChangedSubscriptions[id] = callback;
    }

    removeDataChangedListener(id) {
        delete this.onDataChangedSubscriptions[id];
    }

    SetPriceRange(price_range) {
        this.price_range = price_range
        this.fetchApartments()
    }

    SetAreaRange(area_range) {
        this.area_range = area_range
        this.fetchApartments()
    }

    GetPriceRange(price_range) {
        return this.price_range
    }

    GetAreaRange(area_range) {
       return this.area_range
    }

    SetCenter(latitude, longitude) {
        if(this.mapComponent) {
            //this.mapComponent.setCenter(latitude, longitude)
        }
    };    

    GetData()
    {
        return this.data;
    }

    async fetchApartments() {
        try {
            const response = await fetch(
                `/api/apartments?topLeftLat=${this.bounds.topLeftLat}&topLeftLng=${this.bounds.topLeftLng}&bottomRightLat=${this.bounds.bottomRightLat}&bottomRightLng=${this.bounds.bottomRightLng}&priceRangeMin=${this.price_range.min}&priceRangeMax=${this.price_range.max}&areaRangeMin=${this.area_range.min}&areaRangeMax=${this.area_range.max}`
            );

            if (response.ok) {
                const data = await response.json();
                console.log(`Visible apartments count: ${data.length}`);
                
                this.data = data || [];
                Object.values(this.onDataChangedSubscriptions).forEach((callback) => {
                    if (callback) callback();
                  });              
            } else {
                console.error(`Error while requesting: ${response.statusText}`);
            }
            
        } catch (error) {
            console.error(`Error while requesting: ${error}`);
        }
    }
}
  
const mapController = new MapController();
export default mapController;
