import React from 'react';
import mapController from '../MapController';

function OnCardHover(apartment)
{
  console.log(`OnCardHover ${apartment.latitude} ${apartment.longitude}`)
  mapController.showSpecialMarker([apartment.latitude, apartment.longitude])
}

function OnCardLeave(apartment)
{
  console.log(`OnCardLeave ${apartment.latitude} ${apartment.longitude}`)

  mapController.hideSpecialMarker()
}

function ApartmentCard({ apartment }) {
  return (
    <a 
      href={apartment.url} 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div 
        className="apartment-card" 
        style={{
          display: 'flex',
          border: '1px solid #ddd',
          marginBottom: '10px',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: '#fff',
          alignItems: 'center'
        }}
        onMouseEnter={() => OnCardHover(apartment)}  // Вызов колбека при наведении
        onMouseLeave={() => OnCardLeave(apartment)}  // Вызов колбека при уходе курсора
      >
        <div style={{ flex: '1', marginRight: '10px' }}>
          <img
            src={apartment.image_url}
            alt={apartment.title}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '5px'
            }}
          />
        </div>
        <div style={{ flex: '2' }}>
          <h3>{apartment.title}</h3>
          <p>{apartment.location}</p>
          <p>Цена: {apartment.price} € | Площадь: {apartment.area} м²</p>
        </div>
      </div>
    </a>
  );
}

export default ApartmentCard;
