import React, { useState } from 'react';
import mapController from '../MapController';

function onPriceChange(range)
{
    console.log(`onPriceChange: [${range.min || "min"}, ${range.max || "max"}]`)
    mapController.SetPriceRange(range)
}

function onAreaChange(range)
{
    console.log(`onAreaChange: [${range.min || "min"}, ${range.max || "max"}]`)
    mapController.SetAreaRange(range)
}

function Filter() {
  const [priceRange, setPriceRange] = useState(mapController.GetPriceRange());
  const [areaRange, setAreaRange] = useState(mapController.GetAreaRange());

  // Обработчики для изменений диапазона цены
  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Удаляем все, кроме цифр
    setPriceRange((prev) => ({
      ...prev,
      [name]: newValue === '' ? '' : Number(newValue)
    }));
    onPriceChange({ ...priceRange, [name]: newValue === '' ? '' : Number(newValue) });
  };

  // Обработчики для изменений диапазона площади
  const handleAreaChange = (event) => {
    const { name, value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Удаляем все, кроме цифр
    setAreaRange((prev) => ({
      ...prev,
      [name]: newValue === '' ? '' : Number(newValue)
    }));
    onAreaChange({ ...areaRange, [name]: newValue === '' ? '' : Number(newValue) });
  };

  return (
    <div style={{ padding: '10px', background: '#f1f1f1', marginBottom: '10px' }}>
      <div style={{ marginBottom: '10px' }}>
        <label>Цена за месяц (в евро):</label>
        <div style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            name="min"
            value={priceRange.min}
            onChange={handlePriceChange}
            placeholder="Мин"
            style={{ width: '100px' }}
          />
          <input
            type="text"
            name="max"
            value={priceRange.max}
            onChange={handlePriceChange}
            placeholder="Макс"
            style={{ width: '100px' }}
          />
        </div>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label>Площадь (в м²):</label>
        <div style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            name="min"
            value={areaRange.min}
            onChange={handleAreaChange}
            placeholder="Мин"
            style={{ width: '100px' }}
          />
          <input
            type="text"
            name="max"
            value={areaRange.max}
            onChange={handleAreaChange}
            placeholder="Макс"
            style={{ width: '100px' }}
          />
        </div>
      </div>
    </div>
  );
}

export default Filter;