import React, { useEffect, useState } from 'react';
import MapComponent from './MapComponent';
import LeftPanel from './left_panel/LeftPanel';

function App() {
  const [apartments, setApartments] = useState([]);

  useEffect(() => {
    fetch('/apartments.json')
      .then((response) => response.json())
      .then((data) => setApartments(data))
      .catch((error) => console.error('Ошибка загрузки данных:', error));
  }, []);

  return (
    <div className="app-container" style={{ display: 'flex' }}>
      <div className="apartment-list" style={{ width: '35%', overflowY: 'auto', height: '100vh' }}>
        <LeftPanel/>
      </div>
      <div className="map-container" style={{ width: '65%' }}>
        <MapComponent apartments={apartments} />
      </div>
    </div>
  );
}

export default App;
