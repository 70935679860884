import React from 'react';
import Filter from './Filter';
import ApartmentList from './ApartmentList';

function LeftPanel()
{
    return (
        <div>
            <Filter/>
            <ApartmentList/>
        </div>
    )
}

export default LeftPanel;
